import { useRecordContext, DateField } from 'react-admin';

const CustomDateField = (props) => {
    const record = useRecordContext(props);
    const date = record ? new Date(record.start * 1000) : null;

    const recordValue = {
        id: record.id,
        [props.source]: date 
    }
    
    return date ? <DateField source={props.source} record={recordValue} /> : null;
}

export default CustomDateField;