import * as React from "react";
import { List, Datagrid, TextField, EditButton,  } from "react-admin";
import authProvider from "../../authProvider";
import CustomDateField from "../../subComponents/CustomDateField";

const PodcastList = (props) => {
	
	return (
		<>
			<div className="headingContainer">
				<h1>Live Show</h1>
			</div>
			<List hasCreate={!authProvider.isReadOnly()} sort={{ field: "start", order: "ASC" }} title="All Live Shows" {...props}>
				<Datagrid bulkActionButtons={false} rowClick="edit">
						<TextField source="title" />
						<TextField source="subtitle" />
						<CustomDateField source="start" />
						<EditButton />
					</Datagrid>
			</List>
		</>
	);
};

export default PodcastList;
