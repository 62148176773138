import { useController } from "react-hook-form";
import axios from "axios";

const ImageUpload = (props) => {
	const {
		field: { onChange, value },
	} = useController({ name: props.source, defaultValue: "" });

	const getSignedUrl = async (event) => {
		event.preventDefault();
		const files = event.target.files;

		if (files.length !== 1) {
			console.error("Please select one file only");
			return;
		}

		const formData = new FormData();
		const fileField = props.source === "image" ? "image" : "featuredImage";
		formData.append(fileField, files[0]);

		try {
			const res = await axios.post(`${process.env.REACT_APP_API_URL}/s3`, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			console.log("Upload successful:", res.data); // Log the response
			onChange(res.data.url); // Ensure this points to the correct URL
		} catch (error) {
			console.error("Error uploading image:", error);
		}
	};

	return (
		<div style={{ marginBottom: "20px" }}>
			{value && (
				<div style={{ backgroundColor: "#F7F1F4", width: "fit-content" }}>
					<img alt={props.source} style={{ maxWidth: "100px" }} src={value} />
				</div>
			)}
			<form>
				<input type="file" name="file" onChange={getSignedUrl} />
			</form>
		</div>
	);
};

export default ImageUpload;
