import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

//SEN components
import dataProvider from "../dataProvider";
import logo from "./logo-white.png";

const themeCSS = {
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		borderRadius: "5px",
		color: "white",
	},
	fullHeight: {
		height: "100vh",
		padding: "0px",
	},
	w100: {
		width: "100%",
	},
	w50: {
		width: "50%",
	},
	p40: {
		padding: "40px",
	},
	floatLeft: {
		display: "inline",
		float: "left",
	},
	floatRight: {
		display: "inline",
		textAlign: "right",
		float: "right",
	},
	inline: {
		display: "inline",
	},
	headerBold: {
		fontWeight: 800,
		fontSize: "60px",
	},
	paddingLR7Percent: {
		padding: "0 7.5%",
	},
	paddingBottom20: {
		paddingBottom: "20px",
	},
	marginBottom5: {
		marginBottom: "5px",
	},
	marginBottom20: {
		marginBottom: "20px",
	},
	marginBottom30: {
		marginBottom: "30px",
	},
	verticalAlign: {
		display: "flex",
		alignItems: "center",
	},
	textCenter: { textAlign: "center" },
	submitButton: {
		background: "transparent",
		color: "white",
		border: "1px solid white",
		fontWeight: "bold",
		fontSize: "22px",
		height: "60px",
		textTransform: "none",
		"&:hover": {
			background: "white",
			border: "1px solid white",
			color: "white",
		},
	},
	secondaryButton: {
		color: "white",
		fontWeight: "bold",
		fontSize: "22px",
		height: "60px",
		textTransform: "none",
	},
	inputText: {
		borderColor: "white",
		color: "white",
		borderWidth: "20px",

		"& label": {
			color: "white",
			fontSize: "25px",
		},
		"& .Mui-focused": {
			color: "white",
		},
		"& .MuiInputBase-root": {
			color: "white",
			height: "60px",
		},
		"& .MuiInput-underline:after": {
			color: "white",
			borderBottomColor: "white",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "25px",

			"& fieldset": {
				color: "white",
				borderColor: "white",
			},
			"&:hover fieldset": {
				color: "white",
				borderColor: "white",
			},
			"&.Mui-focused fieldset": {
				color: "white",
				borderColor: "white",
			},
		},
	},
	bgImage: {
		backgroundImage: `linear-gradient(to left,  ${process.env.REACT_APP_PRIMARY_COL}, ${process.env.REACT_APP_SECONDARY_COL})`,
		backgroundRepeat: "no-repeat",
		backgroundAttachment: "fixed",
		backgroundSize: "cover",
	},
	hideMobile: {
		display: "block",
	},
	showMobile: {
		display: "none",
		"@media (max-width: 780px)": {
			display: "block",
		},
	},
	overflowAuto: {
		overflowY: "auto",
		overflowX: "hidden",
	},
};

const themeCSSMobile = {
	root: {
		margin: "0px",
		padding: "0px 0px",
	},

	paddingLR7Percent: {
		paddingBottom: "0px",
	},

	bgImage: {
		backgroundImage: `linear-gradient(to left,  ${process.env.REACT_APP_PRIMARY_COL}, ${process.env.REACT_APP_SECONDARY_COL})`,
	},
	hideMobile: {
		display: "none",
	},
	showMobile: {
		display: "block",
	},
};

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = { emailAddress: "", loading: false, success: false, error: null};
		this.resetPassword = this.resetPassword.bind(this);
		this.keyPress = this.keyPress.bind(this);
	}

	keyPress(e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			this.resetPassword();
		}
	}

	async resetPassword() {
		this.setState({ loading: true });
		try {
			await dataProvider.create("auth/triggerResetPassword", { data: { emailAddress: this.state.emailAddress } });
			this.setState({ loading: false, success: true, error: null });
		} catch (err) {
			if(err.body.errorMessage){
				this.setState({ loading: false, error: err.body.errorMessage });
			} else {
				this.setState({ loading: false, error: "An unknown error occurred" });
			}
		}
	}

	render() {
		const styles = window.innerWidth > 900 ? themeCSS : { ...themeCSS, ...themeCSSMobile };
		return (
			<div style={styles.root}>
				<div style={styles.bgImage}>
					<Container maxWidth="md" style={{ ...styles.fullHeight, ...styles.verticalAlign }}>
						<div style={styles.root}>
							<div style={styles.textCenter}>
								<img src={logo} alt={" "} style={{ width: "50px", marginTop: "20px" }} />
							</div>
							<Typography variant="h4" align="left" style={{ ...styles.paddingTop20, fontWeight: "bold" }}>
								Password Reset
							</Typography>
							<p>Enter your email below to reset your password</p>
							{this.state.success && (
								<Alert severity="success" style={styles.marginBottom20}>
									Please check your email and follow the instructions to reset your password
								</Alert>
							)}
							{!this.state.success && this.state.error && (
								<Alert severity="error" style={styles.marginBottom20}>
									{this.state.error}
								</Alert>
							)}
							<div style={{ ...styles.w100, ...styles.marginBottom20 }}>
								<TextField
									id="standard-email-input"
									className="loginInput whiteBorder"
									label="Email"
									type="email"
									style={{ ...styles.w100, ...styles.inputText }}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									onKeyDown={(event) => {
										this.keyPress(event);
									}}
									onChange={(event) => {
										this.setState({ emailAddress: event.target.value });
									}}
								/>
							</div>
							<div style={{ ...styles.w100, ...styles.marginBottom20 }}>
								<Button
									size="large"
									className={"MuiButton-containedPrimary"}
									style={{ ...styles.w100, ...styles.submitButton }}
									onClick={(event) => {
										event.preventDefault();
										this.resetPassword();
									}}
								>
									{!this.state.loading ? <>Reset Password</> : <CircularProgress size={30} />}
								</Button>
							</div>
						</div>
					</Container>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
