import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

const dataProvider = {
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;

		const query = {
			order: JSON.stringify(order.toLowerCase()),
			limit: perPage,
			offset: JSON.stringify((page - 1) * perPage),
			orderBy: JSON.stringify(field),
		};
		const url = `${apiUrl}/${resource}/all?${stringify(query)}`.replaceAll("%22", "");

		return httpClient(url, {
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ headers, json }) => ({
			data: json,
			total: parseInt(headers.get("content-range").split("/").pop(), 10),
		}));
	},

	getOne: (resource, params) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}`, {
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ json }) => ({
			data: json,
		}));
	},

	getMany: (resource, params) => {
		const query = {
			filter: JSON.stringify({ id: params.ids }),
		};
		const url = `${apiUrl}/${resource}/all?${stringify(query)}`;
		return httpClient(url, {
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ headers, json }) => ({
			data: json,
			total: json.length,
		}));
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify({
				...params.filter,
				[params.target]: params.id,
			}),
		};
		const url = `${apiUrl}/${resource}/${params.target}/${stringify(query)}`;

		return httpClient(url, {
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ headers, json }) => ({
			data: json,
			total: json.length,
		}));
	},

	update: (resource, params) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "PUT",
			body: JSON.stringify(params.data),
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ json }) => ({ data: json }));
	},

	create: (resource, params) =>
		httpClient(`${apiUrl}/${resource}`, {
			method: "POST",
			body: JSON.stringify(params.data),
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ json }) => ({
			data: { ...params.data, id: json.id },
		})),

	delete: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: "DELETE",
			headers: new Headers({
				authorization: localStorage.getItem("authorization"),
			}),
		}).then(({ json }) => ({ data: "Success" })),
};

export default dataProvider;
